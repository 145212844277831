import React, { useState } from 'react';
import Dialog from '../../../../../shared/Dialog/Dialog';
import styles from './FormActivity.module.css';
import { Ticket } from '../../../../../@Types/@Types';
import { useDispatch } from 'react-redux';
import SubmitBtns from './SubmitBtns/SubmitBtns';
import {
    ClientFormActivity,
    FormCurrentTicketActivityValues,
    Trigger,
} from '../../../../../@Types/Activity';
import ActivityTypes, {
    ActivityTriggerTypes,
} from '../../../../../constants/ActivityTypes';
import ConfirmDialog from '../../../../../shared/ConfirmDialog/ConfirmDialog';
import { sendActivity } from '../../../../../controllers/TicketController/TicketService';
import { refresh } from '../../../../../controllers/TicketController/TicketActions';
import FormRenderer from '../../../../../shared/EurekaForm/FormRenderer';
export interface FormActivityProps {
    ticket: Ticket;
    activity: ClientFormActivity;
}

function FormActivityComponent({
    ticket,
    activity,
}: FormActivityProps): JSX.Element {
    const [state, setState] = useState<
        | {
              trigger: Trigger;
              values: Record<string, unknown>;
              showDialog: boolean;
              showConfirmation: boolean;
          }
        | undefined
    >();
    const [valuesCache, setValuesCache] =
        useState<FormCurrentTicketActivityValues>(
            activity.ticketCurrent?.values ?? {
                triggers: {},
            }
        );
    const [loading, setLoading] = useState<null | string | undefined>();
    const dispatch = useDispatch();

    const handleSend = async (
        trigger: Trigger = activity.defaultTrigger,
        values?: Record<string, unknown>
    ): Promise<void> => {
        setLoading(trigger.id);
        const isDefault = trigger.id === activity.defaultTrigger.id;
        let next: typeof state;
        let send = false;
        /** Si está en preview */
        if (!state) {
            if (isDefault) {
                const hasMoreSteps =
                    activity.previewSteps.length !==
                        trigger.form?.rootSteps.length ||
                    activity.previewSteps.find(
                        (idStep) => !trigger.form?.rootSteps.includes(idStep)
                    );
                next = {
                    trigger,
                    values:
                        activity.previewSteps.length && values
                            ? values
                            : valuesCache.default,
                    showDialog: !!hasMoreSteps,
                    showConfirmation: !hasMoreSteps,
                };
            } else {
                next = {
                    trigger,
                    values: valuesCache.triggers[trigger.id],
                    showDialog: true,
                    showConfirmation: false,
                };
            }
        } else {
            next = {
                ...state,
            };
            if (values) next.values = values;
            if (state.showConfirmation) send = true;
            else next.showConfirmation = true;
        }
        if (next.showDialog && !next.trigger.form) {
            next.showDialog = false;
            next.showConfirmation = true;
        }
        if (next.showConfirmation && !next.trigger.confirmation) {
            send = true;
        }

        if (send) {
            try {
                await sendActivity(ticket._id, activity.id, {
                    idActivity: activity.id,
                    type: ActivityTypes.CLIENT_FORM,
                    idTrigger: isDefault ? null : next.trigger.id,
                    values,
                });
                dispatch(refresh(true));
                setState(undefined);
            } catch (error: any) {
                setLoading(undefined);
            }
        } else {
            setState(next);
        }
        setLoading(undefined);
    };

    const defaultForm = activity.defaultTrigger.form;

    const renderSubmitBtns = (
        onSubmit?: () => Promise<Record<string, any> | void>
    ): JSX.Element => {
        return (
            <SubmitBtns
                triggers={activity.showTriggers ? activity.triggers : undefined}
                idTrigger={loading}
                defaultTrigger={
                    activity.showTriggers ? activity.defaultTrigger : undefined
                }
                onSubmit={onSubmit}
                handleSubmit={handleSend}
                viewFormLabel={activity.viewFormLabel}
            />
        );
    };

    return (
        <React.Fragment>
            {state?.showDialog && (
                <Dialog
                    maxWidth="100%"
                    open={true}
                    disableEnforceFocus
                    onClose={(): void => {
                        setState(undefined);
                    }}
                >
                    <div className={styles.formDialogContainer}>
                        {state.trigger.id === activity.defaultTrigger.id && (
                            <div className={styles.containerLbl}>
                                {activity.name}
                            </div>
                        )}
                        <FormRenderer
                            customSubmit={async (values): Promise<void> => {
                                await handleSend(state.trigger, values);
                            }}
                            form={state.trigger.form!}
                            values={state.values}
                            rootSteps={state.trigger.form?.rootSteps}
                            sendLabel={
                                state.trigger.form?.label ?? state.trigger.label
                            }
                        />
                    </div>
                </Dialog>
            )}
            {state?.showConfirmation && state.trigger.confirmation && (
                <ConfirmDialog
                    onConfirm={async (
                        _values: any,
                        setLoading: Function
                    ): Promise<void> => {
                        setLoading(true);
                        await handleSend(state.trigger, state.values);
                        setLoading(false);
                    }}
                    deleting={
                        state.trigger.triggerType === ActivityTriggerTypes.ERROR
                    }
                    title={state.trigger.confirmation.title}
                    btnMsg={state.trigger.confirmation.label}
                    msg={state.trigger.confirmation.message}
                    onClose={(): void => {
                        if (state.showDialog) {
                            const newValues = { ...valuesCache };
                            if (
                                state.trigger.id === activity.defaultTrigger.id
                            ) {
                                newValues.default = state.values;
                            } else {
                                newValues.triggers = {
                                    ...(valuesCache.triggers ?? {}),
                                    [state.trigger.id]: state.values,
                                };
                            }
                            setValuesCache(newValues);
                            setState({ ...state, showConfirmation: false });
                        } else {
                            setState(undefined);
                        }
                    }}
                />
            )}
            <div className={styles.container}>
                {activity.helperText && (
                    <label className={styles.helperMessageLbl}>
                        {activity.helperText}
                    </label>
                )}
                {defaultForm && activity.previewSteps.length && (
                    <FormRenderer
                        rootSteps={activity.previewSteps}
                        form={defaultForm}
                        values={valuesCache.default}
                        customSubmitBtns={(onSubmit): JSX.Element =>
                            renderSubmitBtns(onSubmit)
                        }
                    />
                )}
                {(!defaultForm || !activity.previewSteps.length) &&
                    (activity.showTriggers || activity.viewFormLabel) &&
                    renderSubmitBtns()}
            </div>
        </React.Fragment>
    );
}

export default FormActivityComponent;
