import styles from './Action.module.css';
import ActionAvatar from './ActionAvatar/ActionAvatar';
import React from 'react';
import { Action, Organization, Ticket } from '../../../@Types/@Types';
import Types from '../../../constants/ActionTypes';
import PayloadAction from './Payload/Payload';
import { format } from 'date-fns';
import { getLocale } from '../../../index';
import RatingAction from '../Action/Rating/Rating';
import FormActivityAction from './Activities/FormActivity/FormActivityAction';
import ActivityTypes from '../../../constants/ActivityTypes';
import DateActivityAction from './Activities/DateActivityAction/DateActivityAction';
import FormBtnAction from './FormBtnAction/FormBtnAction';
import { Branding } from '../../../@Types/Branding';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import ViewFormActivityAction from './Activities/ViewFormActivity/ViewFormActivityAction';
interface ActionComponentProps {
    /** Action to display */
    element: Action;
    /** If the action is the last one */
    last: boolean;
    /** indicates if the screen size is really small */
    xsm: boolean;
    /** The currently displayed Ticket */
    ticket: Ticket;
}

interface ActionInfo {
    /** If the current user sent the action */
    sentByCurrentUser: boolean | undefined;
    /** The title of the action */
    title: string | JSX.Element;
    /** The type of the action */
    type: Action['type'];
    /** The visual component of the action (under the title) */
    content?: JSX.Element;
    /** The icon to display */
    icon?: JSX.Element;
}

/**
 * Component that displays an action given its info
 * @returns Action Element
 */
function ActionComponent({
    xsm,
    last,
    ticket,
    element,
}: ActionComponentProps): JSX.Element {
    /** Calc the Action's information */
    const { branding, organization } = useSelector(
        (state: RootState) => state.site
    );
    if (!branding || !organization) return <div></div>;
    const actionInfo = calcInfo(element, organization, branding, ticket);
    if (actionInfo !== null)
        return (
            <div
                className={styles.container}
                style={{
                    marginBottom: last ? 10 : 0,
                    flexDirection: actionInfo.sentByCurrentUser
                        ? 'row-reverse'
                        : 'row',
                }}
            >
                {!actionInfo.sentByCurrentUser && (
                    <div className={styles.avatarContainer}>
                        {actionInfo.icon}
                    </div>
                )}
                <div className={styles.actionContainer}>
                    <div
                        style={{
                            borderRadius:
                                actionInfo.content !== undefined
                                    ? '10px 10px 0 0'
                                    : '10px 10px 10px 10px',
                            backgroundColor: actionInfo.sentByCurrentUser
                                ? branding.colors.secondaryColor
                                : 'var(--light-grey)',
                        }}
                        className={
                            actionInfo.sentByCurrentUser
                                ? styles.actionTitleContainerUser
                                : styles.actionTitleContainer
                        }
                    >
                        <div className={styles.actionTitle}>
                            {actionInfo.title}
                        </div>
                        <label className={styles.actionDate}>
                            {calcDate(element, xsm)}
                        </label>
                    </div>
                    {actionInfo.content !== undefined && (
                        <div className={styles.actionContentContainer}>
                            {actionInfo.content}
                        </div>
                    )}
                </div>
            </div>
        );
    else {
        return <div></div>;
    }
}
export default ActionComponent;

/**
 * Function that returns the Action's info given its type
 * @param element The action to calculate
 * @param organization The current Organization
 * @param organization The current styles
 * @returns An ActionInfo element
 */
function calcInfo(
    element: Action,
    organization: Organization,
    branding: Branding,
    ticket: Ticket
): ActionInfo | null {
    const action: ActionInfo = {
        sentByCurrentUser: false,
        type: element.type,
        title: '',
    };

    switch (element.type) {
        case Types.CLIENT_REPLY: {
            action.title = 'Yo';
            action.content = (
                <React.Fragment>
                    {((element.payload.files &&
                        element.payload.files?.length > 0) ||
                        (element.payload.images &&
                            Object.keys(element.payload.images)?.length > 0) ||
                        (element.payload.text &&
                            element.payload.text?.length > 0)) && (
                        <PayloadAction
                            content={element.payload}
                            color={branding.colors.contrastColor}
                        />
                    )}
                    {element._id === 'CONTENT' && ticket.form && (
                        <FormBtnAction branding={branding} />
                    )}
                </React.Fragment>
            );
            action.sentByCurrentUser = true;
            return action;
        }
        case Types.CLIENT_RATING: {
            action.title = 'Caso Calificado';
            action.content = (
                <RatingAction
                    name={element._id}
                    rating={element.rating}
                    survey={element.survey}
                    branding={branding}
                />
            );
            action.sentByCurrentUser = true;
            return action;
        }
        case Types.AUTO_REPLY: {
            action.title = (
                <React.Fragment>
                    <span className={styles.boldName}>{branding.name}</span>
                    Respondió
                </React.Fragment>
            );
            action.content = (
                <PayloadAction
                    content={element.payload}
                    color={branding.colors.contrastColor}
                />
            );
            action.icon = (
                <ActionAvatar
                    img={branding.images.iconUrl}
                    name={branding.name}
                />
            );
            return action;
        }
        case Types.REPLY: {
            action.title = (
                <React.Fragment>
                    <span className={styles.boldName}>
                        {organization.visibleAgents
                            ? element.agent.name
                            : organization.visibleJobTitle
                            ? element.agent.jobTitle
                            : branding.name}
                    </span>
                    Respondió
                </React.Fragment>
            );
            action.content = (
                <PayloadAction
                    content={element.payload}
                    color={branding.colors.contrastColor}
                />
            );
            action.icon = (
                <ActionAvatar
                    img={branding.images.iconUrl}
                    name={
                        organization.visibleAgents
                            ? element.agent.name
                            : branding.name || ''
                    }
                />
            );
            return action;
        }

        case Types.ACTIVITY: {
            switch (element.activityType) {
                case ActivityTypes.CLIENT_FORM:
                    if (!element.activity) return null;
                    if (!element.values || element.idTrigger === undefined)
                        return null;
                    action.title = element.activity.name;
                    action.content = (
                        <FormActivityAction
                            branding={branding}
                            activity={element.activity}
                            idTrigger={element.idTrigger}
                            values={element.values}
                        />
                    );
                    action.sentByCurrentUser = true;
                    return action;
                case ActivityTypes.CLIENT_VIEW_FORM:
                    if (!element.activity) return null;
                    if (!element.values) return null;
                    action.title = element.activity.name;
                    action.content = (
                        <ViewFormActivityAction
                            branding={branding}
                            activity={element.activity}
                            values={element.values}
                        />
                    );
                    action.icon = (
                        <ActionAvatar
                            img={branding.images.iconUrl}
                            name={branding.name}
                        />
                    );
                    return action;
                case ActivityTypes.SCHEDULE:
                case ActivityTypes.SCHEDULE_CANCEL:
                    action.title =
                        'Visita ' +
                        (element.activityType === ActivityTypes.SCHEDULE_CANCEL
                            ? 'Cancelada'
                            : 'Agendada');
                    action.content = (
                        <DateActivityAction
                            date={element.scheduleDate.startDate}
                            branding={branding}
                            cancelled={
                                element.activityType ===
                                ActivityTypes.SCHEDULE_CANCEL
                            }
                        />
                    );
                    action.icon = (
                        <ActionAvatar
                            img={branding.images.iconUrl}
                            name={branding.name}
                        />
                    );
                    return action;

                default:
                    return null;
            }
        }
        default:
            return null;
    }
}
/**
 * Calcs the date of an action given the current locale
 * @param element Action to calc the date
 * @returns localestring of an action
 */
export const calcDate = (element: Action, xsm?: boolean): string => {
    return format(element.creation_date, xsm ? 'P' : 'Pp', {
        locale: getLocale(),
    });
};
