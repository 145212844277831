import styles from './TicketDetail.module.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import {
    match,
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
} from 'react-router-dom';
import Activity from './Activity/Activity';
import { reset } from '../../controllers/TicketController/TicketActions';
import Loader from '../../shared/Loader/Loader';
import Information from './Information/Information';
import { setBranding } from '../../controllers/_SiteController/SiteActions';
interface IdTicketRouteParam {
    /** Current Ticket */
    idTicket?: string;
}

interface TicketDetailProps extends RouteComponentProps {
    /** If the app is mobile */
    mobile: boolean;
    match: match<IdTicketRouteParam>;
}

function TicketDetail({
    history,
    mobile,
    match: {
        params: { idTicket },
    },
}: TicketDetailProps): JSX.Element {
    const { branding, organization } = useSelector(
        (state: RootState) => state.site
    );
    const ticket = useSelector((state: RootState) => state.ticketDetail.ticket);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!branding && !organization) return;
        if (!mobile && history.location.pathname.includes('/info')) {
            const url =
                history.location.pathname.replace('/info', '') +
                history.location.search;
            history.replace(url);
        }
        if (idTicket) {
            if (!mobile && history.location.pathname.includes('/form')) {
                const url =
                    history.location.pathname.replace('/form', '') +
                    history.location.search;
                history.replace(url);
                dispatch(reset({ idTicket, showForm: true }));
            } else {
                dispatch(reset({ idTicket }));
            }
        }
    }, [idTicket, branding, organization]);

    useEffect(() => {
        if (!branding && organization && ticket) {
            dispatch(setBranding(organization.branding));
        }
    }, [ticket, organization]);
    if (!branding) {
        return <div></div>;
    } else if (ticket === undefined) {
        return (
            <div className={styles.loading}>
                <Loader color={branding?.colors.primaryColor} />
            </div>
        );
    } else if (ticket === null) {
        return <div className={styles.notFound}>404</div>;
    } else if (mobile) {
        return (
            <div className={styles.container}>
                <Switch>
                    <Route path="/tickets/:token/info">
                        <Information mobile={mobile} ticket={ticket} />
                    </Route>
                    <Route path="/tickets/:token">
                        <Activity mobile={mobile} />
                    </Route>
                </Switch>
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.activityContainer}>
                        <Activity mobile={mobile} />
                    </div>
                    <div className={styles.informationContainer}>
                        <Information mobile={mobile} ticket={ticket} />
                    </div>
                </div>
                {/* <div
                    className={styles.copyrightContainer}
                    style={{
                        visibility:
                            organization.domain === 'sinco'
                                ? 'hidden'
                                : 'visible',
                    }}
                >
                    <p className={styles.copyright}>
                        © Copyright 2021{' '}
                        <a href="https://arquimedes.co/" target="_blank">
                            ARQUIMEDES T&D
                        </a>{' '}
                        Todos los derechos reservados
                    </p>
                </div> */}
            </div>
        );
    }
}
export default withRouter((props: any) => <TicketDetail {...props} />);
