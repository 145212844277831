import React, { useState } from 'react';
import { Trigger } from '../../../../../@Types/Activity';
import FormRenderer from '../../../../../shared/EurekaForm/FormRenderer';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import SubmitBtns from '../../../Activity/Activities/FormActivity/SubmitBtns/SubmitBtns';
import Dialog from '../../../../../shared/Dialog/Dialog';
import styles from './FormActivityAction.module.css';
import { Branding } from '../../../../../@Types/Branding';

export interface FormActivityProps {
    activity: {
        name: string;
        helperText?: string;
        triggers: Trigger[];
        defaultTrigger?: Trigger;
    };
    idTrigger: string | null;
    values: Record<string, any>;
    message?: string;
    /** The current org's styles */
    branding: Branding;
}

function FormActivityComponent({
    values,
    message,
    activity,
    idTrigger,
    branding,
}: FormActivityProps): JSX.Element {
    const [showDialog, setShowDialog] = useState(false);
    const trigger = idTrigger
        ? activity.triggers.find((trigger) => trigger.id === idTrigger)
        : activity.defaultTrigger;
    if (!trigger) return <></>;

    const renderForm = (): JSX.Element | void => {
        if (trigger.form?.rootSteps) {
            return (
                <React.Fragment>
                    {trigger.form?.postview?.steps && (
                        <FormRenderer
                            rootSteps={trigger.form?.postview?.steps}
                            postview={true}
                            form={{
                                ...trigger.form,
                                size: {
                                    blockNum: 2,
                                    blockSize: 210,
                                    spacingSize: 20,
                                },
                            }}
                            values={values}
                        />
                    )}
                    {(!trigger.form?.postview?.steps ||
                        trigger.form.postview?.steps.length !==
                            trigger.form.rootSteps.length ||
                        trigger?.form?.postview?.steps.find(
                            (idStep) =>
                                !trigger.form?.rootSteps.includes(idStep)
                        )) && (
                        <div
                            style={{
                                marginBottom:
                                    !trigger.form ||
                                    trigger.form?.postview?.showTriggers
                                        ? 10
                                        : 0,
                            }}
                        >
                            <RoundedButton
                                onClick={(): void => {
                                    setShowDialog(true);
                                }}
                                backgroundColor={branding.colors.primaryColor}
                                text={
                                    !trigger.form.postview?.steps?.length
                                        ? 'Ver Formulario'
                                        : 'Ver más'
                                }
                                fontSize={'15px'}
                                padding="8px 16px"
                            />
                        </div>
                    )}
                </React.Fragment>
            );
        }
    };

    return (
        <React.Fragment>
            {showDialog && trigger.form && (
                <Dialog
                    maxWidth="100%"
                    open={true}
                    disableEnforceFocus
                    onClose={(): void => setShowDialog(false)}
                >
                    <div className={styles.formDialogContainer}>
                        <div className={styles.containerLbl}>
                            {activity.name}
                        </div>
                        <FormRenderer
                            postview={true}
                            rootSteps={trigger.form.rootSteps}
                            form={trigger.form}
                            sendLabel={trigger.label}
                            values={values}
                        />
                    </div>
                </Dialog>
            )}
            <div className={styles.container}>
                {!trigger.form?.postview?.message && activity.helperText && (
                    <label className={styles.helperMessageLbl}>
                        {activity.helperText}
                    </label>
                )}
                {trigger.form?.postview?.message && (
                    <label className={styles.helperMessageLbl}>
                        {message ?? trigger.form.postview.message}
                    </label>
                )}
                {renderForm()}
                {(!trigger.form || trigger.form.postview?.showTriggers) && (
                    <SubmitBtns
                        postview={true}
                        idTrigger={trigger.id}
                        triggers={activity.triggers}
                        defaultTrigger={activity.defaultTrigger}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

export default FormActivityComponent;
